<template>
  <v-app id="inspire">
    <div>
      <title>เมนูการตั้งค่า</title>
      <img
        src="../assets/image/UI Member PHOTO/Banner-08.png"
        class="img-fluid"
        alt="Responsive image"
        style="width: 100%"
      />
      <div style="width: 95%; margin: auto;">
      <div style="margin: 10px 0 0 0;">
        <CCard>
          <CCardBody style="background-color:#e5e5e5; padding: 10px;">
            <div style="display:flex;">
              <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                <h1 style="font-size:16px; color:#FFF; margin: 0 0 0 7px;">1</h1>
              </div>
              <h1 style="font-size:18px;">กรอกข้อมูลส่วนตัว</h1>
            </div>

            <div style="padding: 0 30px 0 30px;">

            <h2 style="font-size: 14px; padding-top: 10px">เบอร์โทรศัพท์มือถือ</h2>
            <v-skeleton-loader style="height: 35px;" v-if="loadingdata.viewdata" type="image"></v-skeleton-loader>
            <CInput
              v-else
              disabled
              type="text"
              v-model="$store.state.profile.numberphone"
              placeholder="numberphone"
              aria-label="numberphone"
              style="font-family: Kanit, sans-serif;"
              aria-describedby="basic-addon1"
            ></CInput>
            
            <h2 style="font-size: 14px; padding-top: 10px">ชื่อ - นามสกุล</h2>

            <template v-if="this.checkConditionlist.name">
              <p style="color: #ff0000; margin: 0 0 0 0">
                *กรุณาใส่ชื่อนามสกุล
              </p>
            </template>

            <v-skeleton-loader style="height: 35px;" v-if="loadingdata.viewdata" type="image"></v-skeleton-loader>
            <CInput
              v-else
              type="text"
              v-model="viewdata.name"
              @input="checkName()"
              placeholder="name"
              aria-label="name"
              style="font-family: Kanit, sans-serif;"
              aria-describedby="basic-addon1"
            ></CInput>
            
            <h2 style="font-size: 14px; padding-top: 10px">เพศ</h2>
            <template v-if="checkConditionlist.sex">
              <p style="color: #ff0000; margin: 0 0 0 0">*กรุณาระบุเพศ</p>
            </template>

            <CCard>
              <CCardBody @change="SelectSex()">
                <input type="radio" id="female" name="sex" value="female">
                <label for="css"><h2 style="font-size: 14px; margin: 0 0 0 10px;">หญิง</h2></label><br>
              
                <input type="radio" id="male" name="sex" value="male">
                <label for="html"><h2 style="font-size: 14px; margin: 0 0 0 10px;">ชาย</h2></label><br>

                <input type="radio" id="not specified" name="sex" value="not specified">
                <label for="javascript"><h2 style="font-size: 14px; margin: 0 0 0 10px;">ไม่ระบุ</h2></label>
              </CCardBody>
            </CCard>

            <h2 style="font-size: 14px">วันเกิด (วันเดือนปี)</h2>
            <template v-if="checkConditionlist.bod">
              <p style="color: #ff0000; margin: 0 0 0 0">
                *กรุณาระบุวันเดือนปีเกิด
              </p>
            </template>
    
            <!-- <div>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <CInput
                      disabled
                      :placeholder="dateShow"
                      v-model="viewdata.bod"
                      style="width: 100%; font-family: Kanit, sans-serif;"
                    />
                  </div>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="date"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  locale="th"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </div> -->
             <div style="width: 100%; ">
              <!-- <date-picker v-model="bod" type="date" lang='th' :placeholder="dateShow" style="width: 100%;"  @change="save" ref="picker"></date-picker> -->
              <date-picker
                :value="new  Date(viewdata.bod)"
                min="1950-01-01"
                locale="th"
                style="font-family: Kanit, sans-serif; width: 100%;">
              </date-picker>
            </div>
            <br/>

            <h1 style="font-size: 16px">จังหวัดที่อาศัยอยู่</h1>
            <template v-if="checkConditionlist.province">
              <p style="color: #ff0000; margin: 0 0 0 0">*กรุณาระบุจังหวัด</p>
            </template>
            <v-skeleton-loader style="height: 35px;" v-if="loadingdata.viewdata" type="image"></v-skeleton-loader>
            <CSelect v-else @change="Province($event)" :options="listprovince" />
            </div>
          </CCardBody>
        </CCard>
      </div>

      <div>
        <CCard>
          <CCardBody :style="cssWraning">
            <div style="display:flex;">
              <div style="background-color:#8350ff; width: 20px; height: 20px; border-radius: 50%; margin: 0 5px;">
                <h1 style="font-size:16px; color:#FFF; margin: 0 0 0 6px;">2</h1>
              </div>
              <h1 style="font-size:18px;">ยอมรับเงื่อนไขในการบริการ</h1>
            </div>
            
            <div style="display: flex; padding: 0 30px 0 30px;">
              <div style="padding-top: 3px; padding-right: 10px">
                <input type="checkbox" id="checkCondition" @click="checkBox()">
              </div>
              <div style="display: row">
                <label>
                  ยอมรับ<a style="color:#8553fe; text-decoration: underline;" target="_blank" href="https://line.teedev.online/files/TermsOfService.pdf">เงื่อนไขบริการ</a>
                  และยินยอมให้จัดการข้อมูลส่วนบุคคลตาม<a style="color:#8553fe; text-decoration: underline;" target="_blank" href="https://line.teedev.online/files/PrivacyPolicy.pdf">นโยบายความเป็นส่วนตัว</a>
                </label>
                <!-- <label>
                  ยอมรับ<a style="color:#8553fe; text-decoration: underline;" target="_blank" v-on:click="dialogService = true">เงื่อนไขบริการ</a>
                  และยินยอมให้จัดการข้อมูลส่วนบุคคลตาม<a style="color:#8553fe; text-decoration: underline;" target="_blank" v-on:click="dialogPrivacyPolicy = true">นโยบายความเป็นส่วนตัว</a>
                </label> -->
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>

      

      <div style="display: flex; justify-content: center; margin: 0 0 25px 0">
        <CButton
          v-on:click="Cancel()"
          style="
            background-color: #ff5c57;
            color: #ffffff;
            margin: 0 10px 0 10px;
            width: 30%;
          "
        >
          <p style="margin: 0 10px">ยกเลิก</p>
        </CButton>
        <CButton
          v-on:click="Confirm()"
          style="
            background-color: #1ec688;
            color: #ffffff;
            margin: 0 10px 0 10px;
            width: 30%;
          "
        >
          <p style="margin: 0 10px">ตกลง</p>
        </CButton>
      </div>

      <div style="padding-bottom: 10px;">
        <CCard>
          <CCardBody style="background-color:#FFF;">
            <CButton
              v-on:click="warningModal_removeaccount = true; removeaccount = 0;"
              style="
                background-color:#7c52f4;
                color: #ffffff;
                display: block;
                margin: 0 auto 0 auto;
                width: 50%;
                text-align: center;
              "
            >
              <p style="margin: 0 10px">ยกเลิกสมาชิก</p>
            </CButton>
            <div style="margin: 5px 0 0 0;">
              <p style="text-align: center; margin: 0; padding: 0; color:#5f5e5e;">การยกเลิกสมาชิก จะไม่ได้รับสิทธิประโยชน์</p>
              <p style="text-align: center; margin: 0; padding: 0; color:#5f5e5e;">และข่าวสารจาก WONDER WASH</p>
            </div>
          </CCardBody>
        </CCard>
      </div>

      <!-- <div> -->
        <!-- <CModal
          title="การเปลี่ยนแปลงข้อมูล"
          color="warning"
          :show.sync="warningModal"
          @update:show="checkCModal($event)"
        > -->
        <!-- <CModal
          title="การเปลี่ยนแปลงข้อมูล"
          color="warning"
        >
          <CCard>
            <CCardBody>
              <div class="d-flex" style="margin: 10px">
                <div style="width: 30%">
                  <center>
                    <img
                      src="../assets/image/UI Member PHOTO/Member icon-02.png"
                      class="img-fluid"
                      alt="Responsive image"
                      style="width: 70%"
                    />
                  </center>
                </div>
                <div style="width: 70%; display: flex">
                  <h1
                    style="
                      font-size: 14px;
                      text-align: center;
                      margin: auto auto;
                    "
                  >
                    {{ this.textpopup }}
                  </h1>
                </div>
              </div>
            </CCardBody>
          </CCard>
          <center>
            <CButton
              v-on:click="GotoPageSetting()"
              style="background-color: #fab116; color: #ffffff; margin: 0 10px"
              >ยืนยัน</CButton
            >
          </center>
          <div slot="footer"></div>
        </CModal>
      </div> -->


      <v-dialog
        v-model="warningModal"
        persistent
        width="100%"
      >
        <v-card>
          <div style="background-color:#7c52f4; height: 40px;">
            <h1 style="font-size: 18px; margin: 0 0 0 20px; padding-top: 8px; color:#FFF;">การเปลี่ยนแปลงข้อมูล</h1>
          </div>
          <div style="padding: 10px;">
            <CCard style="width: 90%; margin: 0 auto 10px auto;">
              <CCardBody style="background-color:#e5e5e5;">
                <div class="d-flex" style="margin: 10px">
                  <div style="width: 30%">
                    <center>
                      <img
                        src="../assets/image/UI Member PHOTO/Member icon-02.png"
                        class="img-fluid"
                        alt="Responsive image"
                        style="width: 70%"
                      />
                    </center>
                  </div>
                  <div style="width: 70%; display: flex">
                    <h1 style=" font-size: 14px; text-align: center; margin: auto auto; ">{{ this.textpopup }}</h1>
                  </div>
                </div>
              </CCardBody>
            </CCard>
                
            <center>
              <CButton v-on:click="GotoPageSetting()" style="background-color: #1ec688; color: #ffffff; margin: 0 10px"><p style="margin: 0;">ยืนยัน</p></CButton>
            </center>
          </div>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="warningModal_removeaccount"
        persistent
        width="100%"
      >
        <v-card>
          <div style="background-color:#7c52f4; height: 40px;">
            <h1 style="font-size: 18px; margin: 0 0 0 20px; padding-top: 8px; color:#FFF;">เปลี่ยนข้อมูลส่วนตัว</h1>
          </div>
          <div style="padding: 10px;">
            <template v-if="removeaccount == 0">
              <CCard style="width: 90%; margin: 0 auto 10px auto;">
                <CCardBody style="background-color:#e5e5e5;">
                  <div class="d-flex" style="margin: 10px">
                    <div style="display: block; margin: 0 auto;">
                      <h1 style="font-size: 14px; text-align: center; margin: auto auto; " >
                        คุณต้องการยกเลิกการเป็นสมาชิก
                      </h1>
                      <h1 style=" font-size: 14px; text-align: center; margin: auto auto; " >
                        ใช่หรือไม่?
                      </h1>
                    </div>
                  </div>
                </CCardBody>
              </CCard>
              <center>
                <CButton v-on:click="warningModal_removeaccount = false" style="width: 70px; background-color: #ff5c57; color: #ffffff; margin: 0 10px" ><p style="margin: 0;">ไม่ใช่</p></CButton>
                <CButton v-on:click="OTP_removeaccount()" style="width: 70px; background-color: #1ec688; color: #ffffff; margin: 0 10px" ><p style="margin: 0;">ใช่</p></CButton>
              </center>
              <div slot="footer"></div>
            </template>
            <template v-if="removeaccount == 1">
              <CCard>
                <CCardBody style="background-color:#e5e5e5;">
                    <div style="display: block;">
                      <h1 style=" font-size: 14px; text-align: center; margin: auto auto; " > ยกเลิกสมาชิกสำเร็จ </h1>
                    </div>
                </CCardBody>
              </CCard>
              <center>
                <CButton v-on:click="GoToRegister()" style="width: 70px; background-color: #1ec688; color: #ffffff; margin: 0 10px" ><p style="margin: 0;">ตกลง</p></CButton>
              </center>
              <div slot="footer"></div>
            </template>
          </div>
        </v-card>
      </v-dialog>
      </div>
    </div>
  </v-app>
</template>



<style scoped>
.v-application--wrap {
  min-height: 0px !important;
}
</style>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import { freeSet } from "@coreui/icons";
import "vue-material-design-icons/styles.css";

const axios = require("axios");
import config from "../config";
import provinceTH from "../config/Province_th";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import "vue2-datepicker/locale/th";
import moment from "moment";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    DatePicker
  },
  
  data() {
    return {
      dialogService:false,
      dialogPrivacyPolicy:false,
      privacy_policy: config.server + '/เงื่อนไขบริการและนโยบายความเป็นส่วนตัว.pdf',
      radioGroup: 1,
      viewdata:{},
      warningModal: false,
      warningModal2: false,
      warningModal_removeaccount: false,
      warningModal_privacy_policy: false,
      removeaccount: 0,
      textpopup: "",
      branchWW: [{ value: "0", label: "กดเพื่อเลือกสาขา" }],
      dayy: [],
      monthh: [],
      yearr: [],
      listprovince: [],
      profile: "",
      name: "",
      sex: "",
      bod: null,

      province: 0,
      checkCondition: false,
      checkConditionlist: {
        name: false,
        sex: false,
        bod: false,
        province: false,
        checkCondition: true,
      },
      date: null,
      menu: false,
      dateShow: "กดเพื่อใส่วันเดือนปีเกิด",
      dialog: false,
      cssWraning:"background-color:#e5e5e5; padding: 10px;",

      dataOTP :{"timeoutOtp":30,"refOTP":"","codeOTP":"","cssOTP":"padding: 0; font-size: 13px; color: #7d7d7d","timer":false},
      loadingdata: {
                    'viewdata':true,
                   },
      urlDomain :"",

    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    var url = window.location.href
    let domain = (new URL(url));
    domain = domain.origin;
    this.urlDomain = domain;
    console.log("url",  this.urlDomain)

    this.profile = this.$store.state.profile;
    this.provincee = provinceTH;

    document.getElementById("checkCondition").checked = true;
    this.API_ViewSetting({"userid":this.$store.state.profile.userId}).then((res)=>{
      this.viewdata = res.data
      this.loadingdata.viewdata = false
      let data =[...provinceTH]
      let buff = {}
      for(var i=0;i<data.length;i++){
        if(data[i]["label"] == this.viewdata.province){
          buff = {...data[i]}
          data.splice(i, 1)
        }
      }
      data[0] = {...buff}
      this.listprovince = [...data]


      var radios = document.getElementsByName('sex');
      if(this.viewdata.sex == "female"){
        radios[0].checked = true
      }
      else if(this.viewdata.sex == "male"){
        radios[1].checked = true
      }
      else if(this.viewdata.sex == "not specif"){
        radios[2].checked = true
      }
      
    })
  },
  methods: {
    Confirm() {
      if (this.viewdata.name == "") {
        this.checkConditionlist.name = true;
      } else {
        this.checkConditionlist.name = false;
      }
      if (this.viewdata.sex == "") {
        this.checkConditionlist.sex = true;
      } else {
        this.checkConditionlist.sex = false;
      }
      if (this.viewdata.bod == "") {
        this.checkConditionlist.bod = true;
      } else {
        this.checkConditionlist.bod = false;
      }
      if (this.viewdata.province == 0) {
        this.checkConditionlist.province = true;
      } else {
        this.checkConditionlist.province = false;
      }
      if (this.checkConditionlist.checkCondition == true) {
        this.cssWraning = "background-color:#e5e5e5; color: #000; padding: 10px;"
      } else {
        this.cssWraning = "background-color:#e5e5e5; color: #F9534E; padding: 10px;"
      }
      if (
        !this.checkConditionlist.name &&
        !this.checkConditionlist.sex &&
        !this.checkConditionlist.otp &&
        !this.checkConditionlist.bod &&
        !this.checkConditionlist.province &&
        this.checkConditionlist.checkCondition == true
      ) {
        let data = {
          userId: this.$store.state.profile.userId,
          sex: this.viewdata.sex,
          name: this.viewdata.name,
          bod: this.viewdata.bod,
          province: this.viewdata.province,
        };
        console.log("qqq",data);
        this.API_changeInfo(data).then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.textpopup = "บันทึกการเปลี่ยนแปลงสำเร็จ.";
            this.warningModal = true;
            this.$store.state.profile.nemeuser = this.name;
            this.$store.state.profile.sex = this.sex;
          } else {
            this.textpopup = "บันทึกการเปลี่ยนแปลงล้มเหลว!";
            this.warningModal = true;
          }
        });
      }
    },
    Cancel() {
      this.$router.push("/settingww");
    },
    SelectSex(){
      var radios = document.getElementsByName('sex');
      for (var i = 0, length = radios.length; i < length; i++) {
        if (radios[i].checked) {
          this.viewdata.sex = radios[i].value
          break;
        }
      }
    },
    checkName() {
      this.name;
      console.log(this.name);
    },
    Province(e) {
      this.viewdata.province = e.target.value;
    },
    checkBox() {
      this.checkConditionlist.checkCondition = document.getElementById("checkCondition").checked;
    },
    checkCModal(e){
      this.warningModal = e;
      if(this.warningModal == false){
        // this.$router.push('/settingww')
        // window.location.href = 'https://line.teedev.online?pageww=setting';
        window.location.href = this.urlDomain+"?pageww=setting"
      }
    },
    checkCModalDelist(e){
      this.warningModal = e;
      if(this.warningModal == false){
        this.warningModal_removeaccount = false
        // window.location.href = 'http://localhost:8080';
        // window.location.href = 'https://line.teedev.online';
        window.location.href = this.urlDomain
      }
    },
    GotoPageSetting(e) {
      this.warningModal = false;
      // this.$router.push('/settingww')
      // window.location.href = 'https://line.teedev.online?pageww=setting';
      window.location.href = this.urlDomain+"?pageww=setting";
    },
    SendOTPAgain(){
      if(this.dataOTP.timeoutOtp == 0){
        this.dataOTP.timeoutOtp = 30
        this.dataOTP.cssOTP = "padding: 0; font-size: 13px; color: #7d7d7d"
        this.OTP_removeaccount()
      }
    },
    OTP_removeaccount() {
      // console.log("Send!")
      // this.removeaccount = 1
      // let data = {"numberphone":this.$store.state.profile.numberphone}
      // console.log(data)
      // if(this.dataOTP.timer == false){
      //   this.API_reqOTP(data).then((res) => {
      //     this.dataOTP.refOTP = res.data.refOTP
      //     this.dataOTP.timer =  setInterval(() => { 
      //                     if(this.dataOTP.timeoutOtp != 0){
      //                       --this.dataOTP.timeoutOtp
      //                     }
      //                     else{
      //                       this.dataOTP.cssOTP = "padding: 0; font-size: 13px; color: #000000"
      //                       clearInterval(this.dataOTP.timer)
      //                       this.dataOTP.timer = false
      //                     }
      //                   }, 1000);
      //   });
      // }

      var data = {
        "userId": this.$store.state.profile.userId
      }
      this.API_Delistaccount(data).then((res)=>{
        console.log(res.data)
        if(res.data.status){
          this.removeaccount = 1
        }
      })
    },

    save(date) {
      this.dateShow =
        date.substring(8, 10) +
        "/" +
        date.substring(5, 7) +
        "/" +
        (parseInt(date.substring(0, 4)) + 543).toString();

      this.$refs.menu.save(date)
      console.log(this.dateShow);
      this.viewdata.bod = this.dateShow
    },
    DelistAccount(){
      var data = {
        "userId": this.$store.state.profile.userId,
        "numberphone": this.$store.state.profile.numberphone,
        "codeOTP":this.dataOTP.codeOTP,
        "refOTP":this.dataOTP.refOTP
      }
      console.log(data)
      this.API_Delistaccount(data).then((res)=>{
        console.log(res.data)
        if(res.data.status){
          this.removeaccount = 2;
        }
      })
    },
    GoToRegister(){
      this.warningModal_removeaccount = false
      // window.location.href = 'http://localhost:8080';
      // window.location.href = 'https://line.teedev.online';

      window.location.href = this.urlDomain
    },
    API_changeInfo(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server + "/changeinfo", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_ViewSetting(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/viewsetting", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_reqOTP(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/reqotp", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_Delistaccount(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/delistaccount", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
  },
};
</script>



